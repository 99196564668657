import CloseIcon from '@mui/icons-material/Close';
import { Box, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as AuggieLogo } from '../assets/logo/auggie_white.svg';

const NavBar = ({ version, showCancel = false, setShowCancelPopup = null }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleShowCancelPopup = () => {
        setShowCancelPopup(true);
    }

    return (
        <>
            {
                isMobile ? (
                    <>
                        <AppBar
                            position="static"
                            elevation={version === 1 ? 0 : 0}
                            sx={{
                                height: '70px',
                                backgroundColor: '#CBD4BD',
                                display: 'flex',
                            }}
                        >
                            <Toolbar sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                {
                                    showCancel ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                position: 'absolute',
                                                marginRight: '50em',
                                                marginTop: '1em'
                                            }}
                                        >
                                            <IconButton size="large" edge="start" onClick={handleShowCancelPopup}>
                                                <CloseIcon
                                                    sx={{
                                                        color: '#000',
                                                        width: '25px',
                                                        height: '20px'
                                                    }}
                                                />
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        <>
                                        </>
                                    )
                                }

                                <Box style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', }}>
                                    < AuggieLogo
                                        style={{
                                            display: 'flex', width: '100px', marginTop: '1em', marginLeft: version === 1 ? '-3rem' : '0'
                                        }}
                                    />
                                </Box>

                            </Toolbar>
                        </AppBar>
                    </>
                ) : (
                    <AppBar
                        position="static"
                        elevation={version === 1 ? 0 : 0}
                        sx={{
                            height: '110px',
                            backgroundColor: '#CBD4BD',
                            display: 'flex',
                        }}
                    >

                        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                            {
                                showCancel ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            position: 'absolute',
                                            marginRight: window.innerWidth > 700 ? '40em' : '50em',
                                            marginTop: '3em'
                                        }}
                                    >
                                        <IconButton size="large" edge="start" onClick={handleShowCancelPopup}>
                                            <CloseIcon
                                                sx={{
                                                    color: '#000',
                                                    width: '30px',
                                                    height: '30px'
                                                }}
                                            />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    <>
                                    </>
                                )
                            }

                            <AuggieLogo
                                style={{ marginTop: '3em' }}
                            />

                        </Toolbar>
                    </AppBar>
                )
            }
        </>
    )
}

export default NavBar;