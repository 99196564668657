import { Box, Typography, useMediaQuery } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import RegistryLoader from '../assets/RegistryLoading.json';
import pback from "../assets/progress-back.png";
import NavBar from "../components/NavBar";

const Loader = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [progress, setProgress] = useState(0);

    const LoaderRegistry = {
        loop: true,
        autoplay: true,
        animationData: RegistryLoader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                const newProgress = oldProgress >= 80 ? 80 : Math.min(oldProgress + Math.random() * 10, 100);
                if (newProgress === 80) {
                    // clearInterval(timer);
                    // navigate('/registry');
                }
                return newProgress;
            });
        }, 1500);

        return () => clearInterval(timer);
    }, [navigate]);


    return (
        <>
            <Box
                sx={{
                    height: window.innerWidth <= 375 ? '130vh' : '120vh',
                    backgroundColor: '#CBD4BD'
                }}
            >
                <NavBar version={2} />

                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box mt={isMobile ? 12 : 8}>
                        {/* <LoaderImage width={isMobile ? "230px" : "330px"} style={{ marginRight: '0px' }} /> */}
                        <Lottie
                            options={LoaderRegistry}
                            //   height={isMobile ? '350px' : '35vh'}
                            width={isMobile ? "230px" : "330px"}
                        //   style={{
                        //     zIndex: 0,
                        //     position: 'absolute',
                        //   }}
                        />
                    </Box>

                    <Box mt={isMobile ? 4 : 4}>
                        <Typography textAlign={'center'} fontFamily='Copernicus' fontSize={isMobile ? "26px" : "46px"} fontWeight="800" color={"rgba(32, 40, 36, 1)"}>
                            Gathering all of the
                        </Typography>
                        <Typography textAlign={'center'} fontFamily='Copernicus' fontSize={isMobile ? "26px" : "46px"} fontWeight="800" color={"rgba(32, 40, 36, 1)"}>
                            products from your registry
                        </Typography>
                    </Box>

                    <Box style={{ marginTop: isMobile ? '16px' : '1em' }}>
                        {
                            isMobile ? (
                                <>
                                    <Typography textAlign={'center'} fontFamily='Untitled-Sans' fontSize={isMobile ? "18px" : "18px"} lineHeight={isMobile ? '22.48px' : '22x'} fontWeight="400" color={"rgba(32, 40, 36, 1)"}>
                                        In a moment we’ll show you all of your
                                    </Typography>
                                    <Typography textAlign={'center'} fontFamily='Untitled-Sans' fontSize={isMobile ? "18px" : "18px"} lineHeight={isMobile ? '22.48px' : '22x'} fontWeight="400" color={"rgba(32, 40, 36, 1)"}>
                                        registry products to review & rate!
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography textAlign={'center'} fontFamily='Untitled-Sans' fontSize={isMobile ? "18px" : "18px"} lineHeight={isMobile ? '30px' : '22x'} fontWeight="400" color={"rgba(32, 40, 36, 1)"}>
                                        In a moment we’ll show you all of your registry products to review & rate!
                                    </Typography>
                                </>)
                        }
                    </Box>

                    <Box style={{ marginTop: isMobile ? '1em' : '4em', width: isMobile ? '300px' : '711px', height: '16px' }}>
                        <LinearProgress variant="determinate" value={progress} sx={{ height: '10px', borderRadius: '10px', backgroundImage: `url(${pback})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: "center center", "& .MuiLinearProgress-bar": { backgroundColor: '#FF8181' } }} />
                    </Box>
                </Box>


            </Box >
        </>
    )
}

export default Loader;