import {
    Box,
    Button,
    Typography,
    useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ZeroIcon } from '../assets/icons/zeroIcon.svg'
import RegistryImage from '../assets/registry/Empty.svg'
import NavBar from '../components/NavBar'
import { selectProducts } from '../store/selectors/selectors'

const Registry = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const productsList = useSelector(selectProducts);

    const onClickHandler = () => {
        navigate('/product')
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            {
                productsList.length === 0 ? (
                    <>
                        <Box
                            sx={{
                                height: window.innerWidth <= 375 ? '130vh' : '100vh',
                                backgroundColor: '#CBD4BD'
                            }}
                        >
                            <NavBar version={1} />

                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box mt={isMobile ? 1 : 4}>
                                    <ZeroIcon width={isMobile ? "70px" : "120px"} style={{ marginRight: '15px' }} />
                                </Box>

                                <Box>
                                    <Typography mt={2} textAlign={'center'} fontFamily='Copernicus' fontSize={isMobile ? "16px" : "20px"} fontWeight="600">
                                        WE’RE SORRY. AN ERROR OCCURED.
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography textAlign={'center'} fontFamily='Copernicus' fontSize={isMobile ? "26px" : "46px"} fontWeight="800">
                                        We found O items from your registry.
                                    </Typography>
                                </Box>

                                <Box style={{ marginTop: '2em', maxWidth: isMobile ? '95%' : '45%', }}>
                                    <Typography textAlign={'center'} fontFamily="Copernicus" fontSize={isMobile ? "16px" : "18px"} fontWeight="700">
                                        Send us an email with your registry link and we’ll add it for you.
                                    </Typography>
                                </Box>

                                <Box display='flex' alignItems='center' style={{ marginTop: '3em' }}>
                                    <Button
                                        onClick={() => { navigate('/') }}
                                        sx={{
                                            borderRadius: '50px',
                                            bgcolor: '#FB9F78',
                                            width: isMobile ? '345px' : '450px',
                                            height: '48px',
                                            color: '#000',
                                            '&:hover': { color: '#000', bgcolor: '#FB9F78' },
                                            boxShadow: '3px 5px 0px 0px'
                                        }}
                                    >
                                        <Typography fontWeight={'700'} fontSize={'14px'}>Email Auggie</Typography>
                                    </Button>
                                </Box>
                            </Box>


                        </Box >
                    </>
                ) : (
                    <>
                        <Box
                            sx={{
                                minHeight: '110vh',
                                backgroundColor: '#CBD4BD',
                            }}
                        >
                            <NavBar version={1} showCancel={false} />

                            <Box display='flex' flexDirection='column' alignItems='center'>
                                {
                                    isMobile ? (
                                        <>
                                            <Box mt={7} display='inline'>
                                                {
                                                    productsList.slice(0, 2).map((e, i) => {
                                                        return (
                                                            <>
                                                                <Box
                                                                    key={i}
                                                                    sx={{
                                                                        backgroundImage: `url(${e.image === "" ? RegistryImage : e.image})`,
                                                                        backgroundSize: 'cover',
                                                                        width: '150px',
                                                                        height: '148px',
                                                                        display: 'inline-block',
                                                                        marginRight: '8px',
                                                                        marginBottom: '10px',
                                                                        borderRadius: '25px',
                                                                        boxShadow: '0px 3.949141502380371px 3.949141502380371px 0px rgba(0, 0, 0, 0.25)'
                                                                    }}
                                                                />
                                                            </>
                                                        )

                                                    })
                                                }
                                            </Box>

                                            <Box display='inline'>
                                                {
                                                    productsList.slice(2, 4).map((e, i) => {
                                                        return (
                                                            i === 1 ? (
                                                                <>
                                                                    <Box
                                                                        sx={{
                                                                            backgroundColor: '#000',
                                                                            display: 'inline-block',
                                                                            borderRadius: '25px',
                                                                            height: '150px',
                                                                            padding: 0,
                                                                            margin: 0,
                                                                        }}
                                                                    >
                                                                        <Typography style={{ zIndex: 2, position: 'absolute', height: '150px', width: '152px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} lineHeight={'40px'} color='#fff' fontFamily={'Copernicus'} fontSize={'40px'} fontWeight={700}>+{productsList.length}</Typography>
                                                                        <Box
                                                                            key={i}
                                                                            sx={{
                                                                                backgroundImage: `url(${e.image === '' ? RegistryImage : e.image})`,
                                                                                backgroundSize: 'cover',
                                                                                width: '152px',
                                                                                height: '150px',
                                                                                display: 'inline-block',
                                                                                borderRadius: '25px',
                                                                                opacity: 0.5,
                                                                                boxShadow: '0px 3.949141502380371px 3.949141502380371px 0px rgba(0, 0, 0, 0.25)'
                                                                            }}
                                                                        >
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Box
                                                                        key={i}
                                                                        sx={{
                                                                            backgroundImage: `url(${e.image === '' ? RegistryImage : e.image})`,
                                                                            backgroundSize: 'cover',
                                                                            width: '152px',
                                                                            height: '150px',
                                                                            display: 'inline-block',
                                                                            marginRight: '10px',
                                                                            borderRadius: '25px',
                                                                            boxShadow: '0px 3.949141502380371px 3.949141502380371px 0px rgba(0, 0, 0, 0.25)'
                                                                        }}
                                                                    />
                                                                </>
                                                            )
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Box mt={8} display='inline'>
                                                {
                                                    productsList.slice(0, 3).map((e, i) => {
                                                        return (
                                                            <>
                                                                <Box
                                                                    key={i}
                                                                    sx={{
                                                                        backgroundImage: `url(${e.image === "" ? RegistryImage : e.image})`,
                                                                        backgroundSize: 'cover',
                                                                        width: '152px',
                                                                        height: '150px',
                                                                        display: 'inline-block',
                                                                        marginRight: '8px',
                                                                        marginBottom: '10px',
                                                                        borderRadius: '25px',
                                                                        boxShadow: '0px 3.949141502380371px 3.949141502380371px 0px rgba(0, 0, 0, 0.25)'
                                                                    }}
                                                                />
                                                            </>
                                                        )

                                                    })
                                                }
                                            </Box>

                                            <Box display='inline'>
                                                {
                                                    productsList.slice(3, 6).map((e, i) => {
                                                        return (
                                                            i === 2 ? (
                                                                <>
                                                                    <Box
                                                                        sx={{
                                                                            backgroundColor: '#000',
                                                                            display: 'inline-block',
                                                                            borderRadius: '25px',
                                                                            height: '150px',
                                                                            padding: 0,
                                                                            margin: 0,
                                                                        }}
                                                                    >
                                                                        <Typography style={{ zIndex: 2, position: 'absolute', height: '150px', width: '152px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} lineHeight={'40px'} color='#fff' fontFamily={'Copernicus'} fontSize={'40px'} fontWeight={700}>+{productsList.length - 6}</Typography>
                                                                        <Box
                                                                            key={i}
                                                                            sx={{
                                                                                backgroundImage: `url(${e.image === "" ? RegistryImage : e.image})`,
                                                                                opacity: 0.5,
                                                                                backgroundSize: 'cover',
                                                                                width: '152px',
                                                                                height: '150px',
                                                                                borderRadius: '25px',
                                                                                display: 'inline-block',
                                                                                boxShadow: '0px 3.949141502380371px 3.949141502380371px 0px rgba(0, 0, 0, 0.25)',
                                                                            }}
                                                                        >
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Box
                                                                        key={i}
                                                                        sx={{
                                                                            backgroundImage: `url(${e.image === "" ? RegistryImage : e.image})`,
                                                                            backgroundSize: 'cover',
                                                                            width: '152px',
                                                                            height: '150px',
                                                                            display: 'inline-block',
                                                                            marginRight: '10px',
                                                                            borderRadius: '25px',
                                                                            boxShadow: '0px 3.949141502380371px 3.949141502380371px 0px rgba(0, 0, 0, 0.25)'
                                                                        }}
                                                                    />
                                                                </>
                                                            )
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </>
                                    )
                                }

                                <Box>
                                    <Typography
                                        fontFamily='Copernicus'
                                        fontSize={isMobile ? '26px' : '46px'}
                                        fontWeight='800'
                                        lineHeight={isMobile ? '39px' : '69px'}
                                        mt={isMobile ? 3 : 5}
                                        color={"rgba(32, 40, 36, 1)"}
                                    >
                                        We found {productsList.length} items
                                    </Typography>
                                    <Typography
                                        fontFamily='Copernicus'
                                        fontSize={isMobile ? '26px' : '46px'}
                                        fontWeight='800'
                                        lineHeight={isMobile ? '39px' : '69px'}
                                        color={"rgba(32, 40, 36, 1)"}
                                    >
                                        from your registry!
                                    </Typography>
                                </Box>

                                <Box display='flex' alignItems='center' style={{ marginTop: '1.85em' }}>
                                    <Button
                                        onClick={onClickHandler}
                                        sx={{
                                            borderRadius: '50px',
                                            bgcolor: '#FB9F78',
                                            width: isMobile ? '345px' : '452px',
                                            height: isMobile ? '41px' : '41px',
                                            color: '#000',
                                            '&:hover': { color: '#000', bgcolor: '#FB9F78' },
                                            boxShadow: "3px 4px 0px 0px rgba(32, 40, 36, 1)",
                                            border: '1px solid #000',
                                            textTransform: 'none'
                                        }}
                                    >
                                        <Typography fontWeight={'500'} fontFamily={'Untitled-Sans'} fontSize={'14px'} color={"rgba(32, 40, 36, 1)"}>Start reviewing your items</Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )
            }
        </>
    )
}

export default Registry
