import { Route, Routes } from 'react-router-dom';
import './App.css';
import InvalidURL from './components/InvalidURL';
import Loader from './components/Loader';
import Registry from './components/Registry';
import ReviewCompleted from './components/ReviewComplete';
import ProductCard from './components/ReviewRegistry';
import HomePage from './pages/HomePage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {/* <Route path="/:token" element={<HomePage />} /> */}
      <Route path="/invalidURL" element={<InvalidURL />} />
      <Route path="/loader" element={<Loader />} />
      <Route path="/registry" element={<Registry />} />
      <Route path='/product' element={<ProductCard />} />
      <Route path='/complete' element={<ReviewCompleted />} />
    </Routes>
  );
}

export default App;