import { Box, Button, Card, CardContent, CardMedia, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoOpinionIcon from '../assets/images/nop.png';
import ImagePlaceholder from '../assets/imgPlaceholder.png';
import { updateReviewList } from '../store/actions/actions';
import { selectSource } from '../store/selectors/selectors';
import './style.css';

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: 'transparent',
    },
    "&.Mui-focusVisible": {
      backgroundColor: 'transparent',
    }
  }
});

const PopupButton = ({ icon, label, backgroundColor }) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: { xs: '8px 20px', sm: '10px 26px' },
    borderRadius: '20px',
    backgroundColor: backgroundColor,
    marginTop: '10px',
    cursor: 'pointer',
  }}>
    <img src={icon} alt={label} style={{ width: '24px', height: '24px' }} />
    <Typography variant="subtitle2">{label}</Typography>
  </Box>
);

const FeedbackSection = ({ selectedIcon, iconSrc, onFeedbackSubmit, currentProduct }) => {
  useEffect(() => {
    if (selectedIcon === 'no opinion') {
      onFeedbackSubmit(selectedIcon);
    }
  }, [])

  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const [feedback, setFeedback] = useState('');
  const source = useSelector(selectSource);

  const promptMap = {
    'dislike': <Typography fontFamily={'Copernicus'} color='rgba(32, 40, 36, 1)' fontWeight={600} lineHeight={'17.53px'} fontSize={isMobile ? '14px' : '16px'} sx={{ mb: 1 }}>Share a little context about why this didn't work for you to help fellow parents</Typography>,
    'like': <Typography fontFamily={'Copernicus'} color='rgba(32, 40, 36, 1)' fontWeight={600} lineHeight={'17.53px'} fontSize={isMobile ? '14px' : '16px'} sx={{ mb: 1 }}>Share what you liked about this product.</Typography>,
    'favorite': <Typography fontFamily={'Copernicus'} color='rgba(32, 40, 36, 1)' fontWeight={600} lineHeight={'17.53px'} fontSize={isMobile ? '14px' : '16px'} sx={{ mb: 1 }}>Tell us why you can’t live without this product.</Typography>
  };

  const getIconText = (selectedIcon) => {
    if (selectedIcon === 'dislike') {
      return 'Not for us';
    }
    if (selectedIcon === 'favorite') {
      return "Can't live without it";
    }
    if (selectedIcon === 'like') {
      return 'Worked for us';
    }
  }

  const handleSubmit = () => {
    const feedbackPayload = {
      ...currentProduct,
      review: {
        comment: feedback,
        rating: getIconText(selectedIcon)
      }
    }

    dispatch(updateReviewList(feedbackPayload));

    onFeedbackSubmit(selectedIcon, feedback);
    setFeedback('');
  };

  const handleNoComment = () => {
    const feedbackPayload = {
      ...currentProduct,
      review: {
        comment: '',
        rating: getIconText(selectedIcon)
      }
    }

    dispatch(updateReviewList(feedbackPayload));

    onFeedbackSubmit(selectedIcon, feedback);
    setFeedback('');
  }

  if (!selectedIcon || selectedIcon === 'no opinion') {
    return null;
  }

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  return (
    <Card
      sx={{
        width: isMobile ? '343px' : '712px',
        height: isMobile ? '585px' : '544px',
        borderRadius: '30px',
        marginTop: isMobile ? '2rem' : '2rem',
      }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        padding: isMobile ? '37px 20px 0px 20px' : '37px 30px 0px 30px',
        gap: isMobile ? '5px' : '12px'
      }}>

        <Box sx={{
          position: 'relative',
          minWidth: '136px',
          height: '122px',
          flexShrink: 0,
        }}>
          <CardMedia
            component="img"
            sx={{ width: '120px', height: isMobile ? '112px' : '106px', objectFit: 'cover' }}
            image={currentProduct.image === '' || currentProduct.image === null || currentProduct.image === undefined || currentProduct.image.includes('.gif') ? ImagePlaceholder : currentProduct.image}
            alt="Product Image"
            style={{ borderRadius: '10px' }}
          />
          <Box sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            transform: 'translate(10%, -40%)',
            borderRadius: '50%',
          }}>
            {/* <IconSrc width={isMobile ? '36px' : '39px'} height={isMobile ? '36px' : '39px'} style={{ marginTop: '20px', marginLeft: '-10px' }} /> */}
            <img src={iconSrc} alt={'Icon'} style={{ width: isMobile ? '41px' : '39px', height: isMobile ? '41px' : '39px', }} />
          </Box>
        </Box>

        <Box sx={{ justifySelf: 'flex-start', alignSelf: 'flex-start', padding: '0px 0px 0px 5px', }}>
          <Typography fontFamily={'Untitled-Sans'} color='rgba(32, 40, 36, 1)' fontWeight={400} fontSize={isMobile ? '14px' : '16px'}>
            {source === 'Amazon' ? '' : currentProduct?.otherOptions[0]?.websiteName}
          </Typography>
          <Typography fontFamily={'Copernicus'} color='rgba(32, 40, 36, 1)' fontWeight={800} fontSize={isMobile ? '18px' : '26px'} lineHeight={isMobile ? '21.28px' : '30.73px'}>
            {truncateText(currentProduct?.title, 45)}
          </Typography>
          <Typography fontFamily={'Untitled-Sans'} color='rgba(32, 40, 36, 1)' fontWeight={500} fontSize={isMobile ? '14px' : '18px'}>
            {source === 'Amazon' ? `$${currentProduct?.completePrice}` : currentProduct?.price}
          </Typography>
        </Box>
      </Box>

      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
          <Box sx={{ width: isMobile ? 'auto' : '514px' }}>
            {promptMap[selectedIcon]}
            <TextField
              fullWidth
              multiline
              id="commentInput"
              rows={isMobile ? 8 : 6}
              placeholder="Add a note"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              onTouchStart={() => document.getElementById("commentInput").focus()}
              variant="outlined"
              sx={{ mb: 2, }}
              InputProps={{
                sx: {
                  backgroundColor: "#fff",
                  border: "1px solid rgba(218, 218, 218, 1)",
                },
                style: {
                  fontFamily: 'Untitled-Sans',
                  fontWeight: '400',
                  fontSize: '18px',
                  lineHeight: '22.48px',
                }
              }}
            />
          </Box>

          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            onTouchStart={() => { handleSubmit() }}
            sx={{
              borderRadius: '50px',
              bgcolor: '#FB9F78',
              width: isMobile ? '300px' : '460px',
              height: '41px',
              color: '#000',
              '&:hover': { color: '#000', bgcolor: '#FB9F78', boxShadow: '3px 5px 0px 0px', },
              boxShadow: '3px 5px 0px 0px',
              marginTop: '0.3rem',
              textTransform: 'none',
              border: '1px solid #000'
            }}
          >
            <Typography fontFamily={'Untitled-Sans'} color='#000' fontWeight={500} letterSpacing={0.2} fontSize={isMobile ? '14px' : '14px'}>
              Next
            </Typography>
          </Button>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 2 }}>
            <IconButton className={classes.root} aria-label="no opinion" onTouchStart={() => { handleNoComment() }} onClick={handleNoComment} sx={{ display: 'flex', alignItems: 'center', }} disableRipple>
              <img src={NoOpinionIcon} alt='No Comment' style={{ width: isMobile ? '32px' : '50px', height: isMobile ? '32px' : '50px' }} />
              <Typography fontFamily={'Untitled-Sans'} color='#000' fontWeight={500} fontSize={isMobile ? '14px' : '14px'} sx={{ ml: 1 }}>No comments.</Typography>
            </IconButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default FeedbackSection;
