import { Box, useMediaQuery } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import pback from "../assets/progress-back.png";

const RegistryLoader = ({ progress, totalProducts }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getNormalizedValue = (currentVal) => {
        if (currentVal === totalProducts) {
            return 0;
        }

        if (currentVal === 0) {
            return 100;
        }

        return ((totalProducts - progress) / (totalProducts)) * 100;
    }

    return (
        <>
            <Box display={'flex'} justifyContent={'center'} width={isMobile ? '343px' : '711px'}>
                <Box style={{ marginTop: isMobile ? '0.5rem' : '10px', width: '100%' }}>
                    <LinearProgress variant="determinate" value={getNormalizedValue(progress)} sx={{ height: isMobile ? '10px' : '16px', borderRadius: '10px', backgroundImage: `url(${pback})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: "center center", "& .MuiLinearProgress-bar": { backgroundColor: '#FF8181' } }} />
                </Box>
            </Box>
        </>
    )
}

export default RegistryLoader;