import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InvalidLinkIcon } from '../assets/icons/invalidLink.svg';
import NavBar from "./NavBar";

const InvalidURL = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box
                sx={{
                    minHeight: '110vh',
                    backgroundColor: '#CBD4BD'
                }}
            >
                <NavBar version={1} />

                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box mt={isMobile ? 1 : 4}>
                        <InvalidLinkIcon width={isMobile ? "70px" : "120px"} style={{ marginRight: '15px' }} />
                    </Box>

                    <Box>
                        <Typography color="#DA314F" textAlign={'center'} fontFamily='Copernicus' fontSize={isMobile ? "26px" : "46px"} fontWeight="800">
                            We’re Sorry, that URL did not work.
                        </Typography>
                    </Box>

                    <Box style={{ marginTop: '2em', width: isMobile ? '95%' : '45%', }}>
                        <Typography fontFamily='Copernicus-Medium' textAlign="left" fontSize={isMobile ? "18px" : "20px"} lineHeight={isMobile ? '30px' : '21px'} fontWeight="600">
                            Try Checking:
                        </Typography>
                    </Box>

                    <Box style={{ marginTop: '1em', maxWidth: isMobile ? '95%' : '45%', }}>
                        <ul>
                            <li>
                                <Typography textAlign={'left'} fontFamily="Copernicus" fontSize={isMobile ? "16px" : "18px"} fontWeight="500">
                                    The URL is valid
                                </Typography>
                            </li>

                            <li>
                                <Typography textAlign={'left'} fontFamily="Copernicus" fontSize={isMobile ? "16px" : "18px"} fontWeight="500">
                                    The URL is for an Amazon or Babylist registry only. No other registries are supported at this time.
                                </Typography>
                            </li>

                            <li>
                                <Typography textAlign={'left'} fontFamily="Copernicus" fontSize={isMobile ? "16px" : "18px"} fontWeight="500">
                                    You’ve provided a URL to your entire Amazon or Babylist registry not a single product on your registry.
                                </Typography>
                            </li>
                        </ul>
                    </Box>

                    <Box display='flex' alignItems='center' style={{ marginTop: '2em' }}>
                        <Button
                            onClick={() => { navigate('/') }}
                            sx={{
                                borderRadius: '50px',
                                bgcolor: '#FB9F78',
                                width: isMobile ? '345px' : '450px',
                                height: '58px',
                                color: '#000',
                                '&:hover': { color: '#000', bgcolor: '#FB9F78' },
                                boxShadow: '3px 5px 0px 0px'
                            }}
                        >
                            <Typography fontWeight={'700'} fontSize={'14px'}>Back</Typography>
                        </Button>
                    </Box>
                </Box>


            </Box >
        </>
    )
}

export default InvalidURL;