import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Box, Button, Card, CardContent, CardMedia, IconButton, Modal, Typography, useMediaQuery } from '@mui/material';
import Popper from '@mui/material/Popper';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TinderCard from 'react-tinder-card';
import DislikeIcon from '../assets/images/dislike.png';
import LikeIcon from '../assets/images/like.png';
import FavoriteIcon from '../assets/images/love.png';
import NoOpinionIcon from '../assets/images/nop.png';
import ImagePlaceholder from '../assets/imgPlaceholder.png';
import line from '../assets/line.png';
import rectangle from '../assets/rectangle.png';
import cross from '../assets/x.png';
import { resetDetails, updateReviewList } from '../store/actions/actions';
import { selectProducts, selectSource } from '../store/selectors/selectors';
import FeedbackSection from './FeedbackForm';
import NavBar from './NavBar';
import RegistryLoader from './RegistryLoader';
import './style.css';
import { usePopper } from './usePopper';

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: 'transparent',
    },
    "&.Mui-focusVisible": {
      backgroundColor: 'transparent',
    }
  }
});

const StackCard = ({ isFirst }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        top: isMobile ? '15px' : '30px',
        left: isMobile ? '0.85em' : '1.8em',
        zIndex: '-1',
        border: '0px solid #ff0000',
        // boxShadow: '0px 9px 9px -4.5px rgba(0, 0, 0, 0.04); 0px 17.99px 22.49px -4.5px rgba(0, 0, 0, 0.1)',
        // backgroundColor: '#000'
        // boxShadow: '0px 8.995983123779297px 8.995983123779297px -4.497991561889648px rgba(0, 0, 0, 0.04) 0px 17.991966247558594px 22.489959716796875px -4.497991561889648px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Card
        sx={{
          width: isMobile ? '308.5px' : '642px',
          height: isMobile ? '585px' : '536px',
          borderRadius: '30px',
          marginTop: isMobile ? '2rem' : '2rem',
          border: '2px solid #e4e4e4',
          // boxShadow: '0px 8.995983123779297px 8.995983123779297px -4.497991561889648px rgba(0, 0, 0, 0.04) 0px 17.991966247558594px 22.489959716796875px -4.497991561889648px rgba(0, 0, 0, 0.1)',
          // boxShadow: '0px 0px 0px',
          // boxShadow: '0px 9px 9px -4.5px rgba(0, 0, 0, 0.04); 0px 17.99px 22.49px -4.5px rgba(0, 0, 0, 0.1)',
          boxShadow: 'none',
          backgroundColor: '#e4e4e4',
          opacity: isFirst ? '0.1' : '1'
        }}
      >
      </Card>

    </Box >
  )
}

const PopupButton = ({ icon, label, backgroundColor }) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: { xs: '8px 20px', sm: '10px 26px' },
    borderRadius: '20px',
    backgroundColor: backgroundColor,
    marginTop: '10px',
    cursor: 'pointer',
  }}>
    <img src={icon} alt={label} style={{ width: '24px', height: '24px' }} />
    <Typography variant="subtitle2">{label}</Typography>
  </Box>
);

const ProductCard = ({ swipe, currentProduct, globalIndex, currentIndex, setCurrentIndex, listLength, isFirst, setIsFirst }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const iconSize = { xs: { width: '50px', height: '50px' }, sm: { width: '75px', height: '75px' } };
  const iconSizeSmall = { xs: { width: '30px', height: '30px' }, sm: { width: '40px', height: '40px' } };

  const [selectedIcon, setSelectedIcon] = useState(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const source = useSelector(selectSource);

  const { ref, anchorEl, togglePopper } = usePopper();
  const [open, setOpen] = useState(true);

  const handleFeedbackAndMoveNext = (feedbackType) => {
    // setSelectedIcon(feedbackType);
    swipe(feedbackType, currentIndex);

    // if (currentIndex == 0) {
    //   navigate('/complete');
    // }

    setSelectedIcon(null);
  };

  const iconSrcMap = {
    'dislike': DislikeIcon,
    'like': LikeIcon,
    'favorite': FavoriteIcon,
  };

  const backgroundColors = {
    'dislike': '#DAC9FF',
    'like': '#C9FFFC',
    'favorite': '#FF8181',
    'no opinion': '#F0E3D3'
  };

  const handleIconClick = (icon) => {
    if (icon === 'no opinion') {
      setSelectedIcon(icon);
      // handleFeedbackAndMoveNext(icon);
      // swipe(icon, currentIndex);

      // if (currentIndex == 0) {
      //   navigate('/complete');
      // }

      // setSelectedIcon(null);
    } else {
      setSelectedIcon(icon);
      // setIsFlipped(true);
      // handleFeedbackAndMoveNext(icon, 'feedback');
    }
  };

  const handleImDone = () => {
    // const feedbackPayload = {
    //   ...currentProduct,
    //   review: {
    //     comment: '',
    //     rating: ''
    //   }
    // }

    // dispatch(updateReviewList(feedbackPayload));

    navigate('/complete');
  }

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  useEffect(() => {
    if (currentProduct.image === '' || currentProduct.image === null) {
      if (currentIndex < listLength - 1) {
        setCurrentIndex(currentIndex + 1);
        setSelectedIcon(null);

        const feedbackPayload = {
          ...currentProduct,
          review: {
            comment: '',
            rating: ''
          }
        }

        dispatch(updateReviewList(feedbackPayload));
      } else {
        const feedbackPayload = {
          ...currentProduct,
          review: {
            comment: '',
            rating: ''
          }
        }

        dispatch(updateReviewList(feedbackPayload));

        navigate('/complete');
      }
    }
  }, [])

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        // right: '50%',
        zIndex: 0,
      }}
    >

      {selectedIcon && (
        <FeedbackSection
          selectedIcon={selectedIcon}
          iconSrc={iconSrcMap[selectedIcon]}
          onFeedbackSubmit={handleFeedbackAndMoveNext}
          currentProduct={currentProduct}
        />
      )}

      {selectedIcon === null && (
        <Card
          sx={{
            width: isMobile ? '343px' : '712px',
            height: isMobile ? '585px' : '544px',
            boxShadow: 'none',
            borderRadius: '30px',
            marginTop: isMobile ? '2rem' : '2rem',
          }}
          style={{ boxShadow: isFirst ? '0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .03)' : '', }}
        >

          {
            isFirst && currentIndex === 9 ? (
              <>
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', }}>
                  <CardMedia
                    component="img"
                    sx={{ width: isMobile ? '343px' : '282px', height: isMobile ? '292px' : '240px', padding: '2em' }}
                    image={currentProduct.image}
                    alt="Product image"
                    style={{ opacity: '0.1' }}
                  />
                  <CardContent sx={{ display: 'flex', height: '100%', flexDirection: 'column', margin: 0, marginTop: isMobile ? -2 : 0, padding: 0, paddingLeft: isMobile ? 2 : 0, }}>
                    {currentProduct.otherOptions && (
                      <Typography color="text.secondary" fontFamily={'Untitled-Sans'} fontSize={'14px'} fontWeight={400}>
                        {currentProduct.otherOptions[0]?.websiteName}
                      </Typography>
                    )}
                    <Typography fontFamily={'Copernicus'} fontSize={isMobile ? '14px' : '18px'} fontWeight={800}>
                      {truncateText(currentProduct.title, 45)}
                    </Typography>
                    <Typography color="text.primary">
                      {/* ${currentProduct.completePrice} */}
                    </Typography>
                  </CardContent>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'left' : 'center', justifyContent: isMobile ? 'flex-start' : 'center', }} style={{ padding: isMobile ? '0px' : '20px', height: isMobile ? 'auto' : '268px' }}>
                  <CardMedia
                    component="img"
                    sx={{ width: isMobile ? '343px' : '314px', height: isMobile ? '292px' : '268px', borderRadius: isMobile ? '0px' : '20px' }}
                    image={currentProduct.image === '' || currentProduct.image === null || currentProduct.image === undefined || currentProduct.image.includes('.gif') ? ImagePlaceholder : currentProduct.image}
                    alt="Product image"
                  />
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', maxHeight: isMobile ? '106px' : '268px' }} style={{ padding: isMobile ? '16px, 16px, 32px, 16px' : '0px, 0px, 18px, 0px' }}>
                    {currentProduct.otherOptions && (
                      <Typography fontFamily={'Untitled-Sans'} fontSize={isMobile ? '14px' : '16px'} fontWeight={400} color={"rgba(32, 40, 36, 1)"} style={{ marginBottom: isMobile ? '4px' : '8.5px' }}>
                        {currentProduct?.otherOptions[0]?.websiteName}
                      </Typography>
                    )}
                    <Typography fontFamily={'Copernicus'} fontSize={isMobile ? '18px' : '32px'} fontWeight={800} lineHeight={isMobile ? '21.28px' : '37.82px'} color={"rgba(32, 40, 36, 1)"} style={{ marginBottom: isMobile ? '4px' : '8.5px', }}>
                      {truncateText(currentProduct.title, 45)}
                    </Typography>
                    {
                      source === 'Amazon' ?
                        (
                          <>
                            {
                              currentProduct?.completePrice !== "" ?
                                (
                                  <>
                                    <Typography fontFamily={'Untitled-Sans'} fontSize={isMobile ? '14px' : '20px'} fontWeight={500} color={"rgba(32, 40, 36, 1)"}>
                                      {currentProduct?.completePrice === "Price not available" ? "" : `$${currentProduct?.completePrice}`}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <Typography fontFamily={'Untitled-Sans'} fontSize={isMobile ? '14px' : '20px'} fontWeight={500} color={"rgba(32, 40, 36, 1)"}>
                                      {currentProduct?.otherOptions[0]?.quotePrice}
                                    </Typography>
                                  </>
                                )
                            }
                          </>
                        ) : (
                          <>
                            {
                              currentProduct?.price !== "" ?
                                (
                                  <>
                                    <Typography fontFamily={'Untitled-Sans'} fontSize={isMobile ? '14px' : '20px'} fontWeight={500} color={"rgba(32, 40, 36, 1)"}>
                                      {currentProduct?.price}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <Typography fontFamily={'Untitled-Sans'} fontSize={isMobile ? '14px' : '20px'} fontWeight={500} color={"rgba(32, 40, 36, 1)"}>
                                      {currentProduct?.otherOptions[0]?.quotePrice}
                                    </Typography>
                                  </>
                                )
                            }
                          </>
                        )
                    }
                  </CardContent>
                </Box>
              </>
            )
          }

          {
            isFirst && currentIndex === 9 ? (
              <>
                <Box style={{ marginTop: isMobile ? "-50px" : "-60px", marginBottom: isMobile ? "30px" : "50px" }} ref={ref}>
                  <ClickAwayListener onClickAway={() => { setIsFirst(false) }}>
                    <Popper open={open} anchorEl={anchorEl} placement={'top'} sx={{ zIndex: 1300 }}>
                      <Box style={{ width: isMobile ? '353px' : '642px', height: isMobile ? '178px' : '206px', zIndex: 1300, backgroundColor: '#fff', borderRadius: '20px', }}>
                        <Button style={{ position: 'absolute', left: isMobile ? '84%' : '90%', color: "#000", display: 'inline-block', marginTop: '4px' }} onClick={() => { setIsFirst(false) }}><img src={cross} alt="x" style={{ width: "24px", height: '24px' }} /></Button>

                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px', paddingTop: '40px' }}>
                          <Typography width={isMobile ? "317px" : "477.9px"} lineHeight={isMobile ? '30px' : '39px'} textAlign={'center'} alignSelf={'center'} fontFamily={'Copernicus'} fontWeight={800} fontSize={isMobile ? '20px' : '26px'} color={'rgba(32, 40, 36, 1)'}>
                            Give a quick reaction to each
                          </Typography>

                          <Typography style={{ marginBottom: '12px' }} width={isMobile ? "317px" : "477.9px"} lineHeight={isMobile ? '30px' : '39px'} textAlign={'center'} alignSelf={'center'} fontFamily={'Copernicus'} fontWeight={800} fontSize={isMobile ? '20px' : '26px'} color={'rgba(32, 40, 36, 1)'}>
                            item from your registry
                          </Typography>

                          <Typography width={isMobile ? "317px" : "606px"} lineHeight={isMobile ? '17.49px' : '22.48px'} textAlign={'center'} alignSelf={'center'} fontFamily={'Untitled-Sans'} fontWeight={400} fontSize={isMobile ? '14px' : '18px'} color={'rgba(32, 40, 36, 1)'}>
                            Add comments for the stuff you can't live without, or why something didn't work out
                          </Typography>

                          {/* <Typography width={isMobile ? "317px" : "606px"} lineHeight={isMobile ? '17.49px' : '22.48px'} textAlign={'center'} alignSelf={'center'} fontFamily={'Untitled-Sans'} fontWeight={400} fontSize={isMobile ? '14px' : '18px'} color={'rgba(32, 40, 36, 1)'}>
                            why something didn't work out
                          </Typography> */}
                        </Box>
                      </Box>
                      <img src={rectangle} alt="" style={{ position: 'absolute', zIndex: -1, top: isMobile ? 164 : 181, left: "46%", width: isMobile ? '30px' : '50px', height: isMobile ? '30px' : '50px' }} />
                    </Popper>
                  </ClickAwayListener>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: isMobile ? -1 : -3, }} >
                  <Box style={{ boxShadow: '0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .3)', width: isMobile ? '98%' : '80%', height: isMobile ? 'auto' : 'auto', borderRadius: '20px', paddingTop: '15px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: isMobile ? '50px' : '0px', marginTop: isMobile ? '-10px' : '0px' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: isMobile ? '100px' : '114px', width: isMobile ? '65px' : '200px' }}>
                        <IconButton disableRipple className={classes.root} aria-label="not for us" sx={{ "&:hover": { cursor: 'default', } }}>
                          <img src={DislikeIcon} alt='Dislike' style={{ width: isMobile ? '54px' : '70px', height: isMobile ? '54px' : '70px' }} />
                        </IconButton>
                        <Typography width={isMobile ? "65px" : "200px"} lineHeight={'17px'} textAlign={'center'} alignSelf={'center'} fontFamily={'Untitled-Sans'} fontWeight={500} fontSize={isMobile ? '14px' : '13px'} color={'rgba(32, 40, 36, 1)'}>Not for us!</Typography>
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: isMobile ? '100px' : '114px', width: isMobile ? '65px' : '200px' }}>
                        <IconButton disableRipple className={classes.root} aria-label="worked for us" sx={{ "&:hover": { cursor: 'default', } }}>
                          <img src={LikeIcon} alt='Dislike' style={{ width: isMobile ? '54px' : '70px', height: isMobile ? '54px' : '70px' }} />
                        </IconButton>
                        <Typography width={isMobile ? "65px" : "200px"} lineHeight={'17px'} textAlign={'center'} alignSelf={'center'} fontFamily={'Untitled-Sans'} fontWeight={500} fontSize={isMobile ? '14px' : '13px'} color={'rgba(32, 40, 36, 1)'}>Worked for us.</Typography>
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', height: isMobile ? '100px' : '114px', width: isMobile ? '65px' : '200px' }}>
                        <IconButton disableRipple className={classes.root} aria-label="can't live without" sx={{ "&:hover": { cursor: 'default', } }}>
                          <img src={FavoriteIcon} alt='Dislike' style={{ width: isMobile ? '54px' : '70px', height: isMobile ? '54px' : '70px' }} />
                        </IconButton>
                        <Typography width={isMobile ? "65px" : "200px"} lineHeight={'17px'} textAlign={'center'} alignSelf={'center'} fontFamily={'Untitled-Sans'} fontWeight={500} fontSize={isMobile ? '14px' : '13px'} color={'rgba(32, 40, 36, 1)'}>Cant live without!</Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', height: isMobile ? 'auto' : '80px', mt: isMobile ? 1 : 2 }}>
                      <IconButton disableRipple className={classes.root} aria-label="no opinion" sx={{ display: 'flex', alignItems: 'center', "&:hover": { cursor: 'default', } }}>
                        <img src={NoOpinionIcon} alt='Dislike' style={{ width: isMobile ? '32px' : '50px', height: isMobile ? '32px' : '50px' }} />
                        <Typography fontFamily={'Untitled-Sans'} fontWeight={500} fontSize={isMobile ? '14px' : '14px'} color={'rgba(32, 40, 36, 1)'} sx={{ ml: isMobile ? 1 : 2, mt: isMobile ? 0 : 0 }}>No opinion.</Typography>
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: isMobile ? '50px' : '0px', marginTop: isMobile ? '-10px' : '0px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: isMobile ? '100px' : '114px', width: isMobile ? '65px' : '200px' }}>
                    <IconButton onTouchStart={() => { handleIconClick('dislike') }} disableRipple className={classes.root} aria-label="not for us" onClick={() => handleIconClick('dislike')}>
                      <img src={DislikeIcon} alt='Dislike' style={{ width: isMobile ? '54px' : '70px', height: isMobile ? '54px' : '70px' }} />
                    </IconButton>
                    <Typography width={isMobile ? "65px" : "200px"} lineHeight={'17px'} textAlign={'center'} alignSelf={'center'} fontFamily={'Untitled-Sans'} fontWeight={500} fontSize={isMobile ? '14px' : '13px'} color={'rgba(32, 40, 36, 1)'}>Not for us!</Typography>
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: isMobile ? '100px' : '114px', width: isMobile ? '65px' : '200px' }}>
                    <IconButton disableRipple className={classes.root} aria-label="worked for us" onClick={() => handleIconClick('like')} onTouchStart={() => { handleIconClick('like') }}>
                      <img src={LikeIcon} alt='Dislike' style={{ width: isMobile ? '54px' : '70px', height: isMobile ? '54px' : '70px' }} />
                    </IconButton>
                    <Typography width={isMobile ? "65px" : "200px"} lineHeight={'17px'} textAlign={'center'} alignSelf={'center'} fontFamily={'Untitled-Sans'} fontWeight={500} fontSize={isMobile ? '14px' : '13px'} color={'rgba(32, 40, 36, 1)'}>Worked for us.</Typography>
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', height: isMobile ? '100px' : '114px', width: isMobile ? '65px' : '200px' }}>
                    <IconButton disableRipple className={classes.root} aria-label="can't live without" onClick={() => handleIconClick('favorite')} onTouchStart={() => { handleIconClick('favorite') }}>
                      <img src={FavoriteIcon} alt='Dislike' style={{ width: isMobile ? '54px' : '70px', height: isMobile ? '54px' : '70px' }} />
                    </IconButton>
                    <Typography width={isMobile ? "65px" : "200px"} lineHeight={'17px'} textAlign={'center'} alignSelf={'center'} fontFamily={'Untitled-Sans'} fontWeight={500} fontSize={isMobile ? '14px' : '13px'} color={'rgba(32, 40, 36, 1)'}>Cant live without!</Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center', justifyContent: 'center', height: isMobile ? 'auto' : '80px', mt: isMobile ? 2 : 2, '&:hover': { cursor: 'pointer' } }}>
                  <IconButton disableRipple className={classes.root} aria-label="no opinion" onClick={() => handleIconClick('no opinion')} onTouchStart={() => { handleIconClick('no opinion') }}>
                    <img src={NoOpinionIcon} alt='Dislike' style={{ width: isMobile ? '32px' : '50px', height: isMobile ? '32px' : '50px' }} />
                  </IconButton>
                  <Typography fontFamily={'Untitled-Sans'} fontWeight={500} fontSize={isMobile ? '14px' : '14px'} color={'rgba(32, 40, 36, 1)'} sx={{ ml: isMobile ? 0 : 2, mt: isMobile ? 0.4 : 0 }} onClick={() => handleIconClick('no opinion')} onTouchStart={() => { handleIconClick('no opinion') }}>No opinion.</Typography>
                </Box>
              </>
            )
          }
        </Card>
      )
      }

      {/* {
        selectedIcon === null && (
          <Typography color={'rgba(32, 40, 36, 1)'} fontFamily={'Untitled-Sans'} fontWeight={400} fontSize={isMobile ? '14px' : '18px'} sx={{ marginTop: isMobile ? '2rem' : '5rem', textDecoration: 'underline', "&:hover": { cursor: isFirst && currentIndex === listLength - 1 ? 'default' : 'pointer' } }} onClick={isFirst && currentIndex === listLength - 1 ? () => { } : () => handleImDone()} onTouchStart={isFirst && currentIndex === listLength - 1 ? () => { } : () => handleImDone()}>
            I'm done
          </Typography>
        )
      } */}
    </Box >
  );
};

const ReviewRegistry = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const pList = useSelector(selectProducts).filter((product) => {
    return !product.title.includes('Gift Card') && (product.price !== "" || product?.otherOptions[0]?.quotePrice !== "");
  });

  const [isFirst, setIsFirst] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(pList?.length);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [productList, setProductList] = useState(pList?.length > 10 ? pList.slice(0, 10) : pList);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const childRefs = useMemo(
    () =>
      Array(pList?.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  )

  const handleImDone = () => {
    // const feedbackPayload = {
    //   ...currentProduct,
    //   review: {
    //     comment: '',
    //     rating: ''
    //   }
    // }

    // dispatch(updateReviewList(feedbackPayload));

    navigate('/complete');
  }

  const onLeave = (direction, currentIndex) => {
    // console.log(direction);
  }

  const swiped = (direction, currentIndex) => {
    // if (currentIndex < pList?.length) {
    //   setCurrentIndex(currentIndex - 1);
    // }
  }

  const swipe = async (feedbackType, index) => {
    // console.log('Index in Card: ', index);
    setCurrentIndex(currentIndex - 1);

    if (currentIndex - 1 <= 0) {
      navigate('/complete');
    }

    if (index === 0) {
      // console.log("Change Now");
      if ((page + 1) * pageSize < pList?.length) {
        // console.log("Normal Page");
        // console.log("Slice: ", (page * pageSize), " ", (page + 1) * pageSize);
        setProductList(pList.slice((page * pageSize), (page + 1) * pageSize));
        setPage(page + 1);
      } else {
        // console.log("Reduced Page");
        // console.log("Slice: ", (page * pageSize), " ", pList.length);
        setProductList(pList.slice((page * pageSize), pList.length));
        setPage(page + 1);
      }
    }

    let dir = "";
    if (feedbackType === "dislike") {
      dir = "left";
    } else if (feedbackType === "like") {
      dir = "up";
    } else if (feedbackType === "favorite") {
      dir = "right";
    } else if (feedbackType === "no opinion") {
      dir = isMobile ? "left" : "down";
    } else {
      dir = "right";
    }

    if (index < productList.length) {
      await childRefs[index].current.swipe(dir)
      const elements = document.querySelectorAll(".swipe");
      elements.forEach(element => {
        if (element.style.transform != "translate3d(0px, 0px, 0px) rotate(0deg)") {
          element.style.display = 'none';
        }
      })
    }
  }

  const handleReset = () => {
    dispatch(resetDetails());
    navigate('/');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {

  }, [productList])

  // console.log('Product List: ', pList.slice(170, 171));
  // console.log('Current Page: ', page);
  // console.log('Current Index: ', currentIndex);

  return (
    <>
      <Box sx={{
        height: window.innerHeight <= 800 ? '130vh' : window.innerHeight > 800 && window.innerHeight <= 850 && window.innerWidth >= 500 ? '120vh' : '120vh',
        backgroundColor: '#CBD4BD',
        backgroundRepeat: 'repeat',
        backgroundSize: 'cover',
        // overflowY: 'hidden',
        overflow: 'hidden',
      }}>
        <NavBar version={2} showCancel={true} setShowCancelPopup={setShowCancelPopup} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            m: isMobile ? '0px' : '50px',
          }}
        >
          <Typography textAlign={'center'} color={"rgba(32, 40, 36, 1)"} fontSize={isMobile ? '26px' : '46px'} fontFamily={'Copernicus'} fontWeight={800}>Review in Progress...</Typography>
          <RegistryLoader progress={currentIndex} totalProducts={pList?.length} />

          <div className='cardContainer' style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
            {productList?.map((product, index) =>
              <TinderCard
                ref={childRefs[index]}
                className='swipe pressable'
                key={product.title}
                preventSwipe={['left', 'right', 'up', 'down']}
                flickOnSwipe={false}
                onSwipe={(dir) => swiped(dir, currentIndex)}
                onCardLeftScreen={(dir) => onLeave(dir, currentIndex)}
              >
                <ProductCard swipe={swipe} currentProduct={product} globalIndex={currentIndex} currentIndex={index} setCurrentIndex={setCurrentIndex} listLength={pList.length} isFirst={isFirst} setIsFirst={setIsFirst} />
                <StackCard isFirst={isFirst} />
              </TinderCard>
            )}

            <Box
              sx={{
                position: 'absolute',
                top: isMobile ? '625px' : '576px',
                width: isMobile ? '343px' : '712px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography color={'rgba(32, 40, 36, 1)'} fontFamily={'Untitled-Sans'} fontWeight={400} fontSize={isMobile ? '14px' : '18px'} sx={{ marginTop: isMobile ? '2rem' : '5rem', textDecoration: 'underline', "&:hover": { cursor: isFirst ? 'default' : 'pointer' } }} onClick={isFirst ? () => { } : () => handleImDone()} onTouchStart={isFirst ? () => { } : () => handleImDone()}>
                I'm done
              </Typography>
            </Box>
          </div>
        </Box>
      </Box >

      <Modal
        open={showCancelPopup}
        onClose={() => { }}
        aria-labelledby="registration-modal-title"
        aria-describedby="registration-modal-description"
        style={{
          display: 'flex',
          alignItems: isMobile ? 'flex-end' : 'center',
          justifyContent: 'center',
        }}
        disableEscapeKeyDown
        disableAutoFocus={true}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#FCF8F6',
              padding: isMobile ? '12px, 0px, 48px, 0px' : '24px',
              textAlign: 'center',
              width: isMobile ? '100vw' : '441px',
              height: isMobile ? '367px' : '350px',
              borderRadius: isMobile ? "35px 35px 0px 0px" : "20px",
              outline: 'none',
            }}
          >
            {
              isMobile && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <img src={line} alt="" style={{ width: '60px', marginTop: '-20px' }} />
                </Box>
              )
            }
            <Box
              sx={{
                maxWidth: isMobile ? '306px' : '350px',
                // maxHeight: '255px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography letterSpacing={"-1px"} fontFamily='Copernicus' fontSize={isMobile ? "22px" : "22px"} fontWeight="800" style={{ maxWidth: isMobile ? '306px' : '393px', color: 'rgba(32, 40, 36, 1)' }} lineHeight={isMobile ? '33px' : '39px'}>
                Stop reviewing your registry?
              </Typography>
              <Typography fontFamily='Untitled-Sans' lineHeight={'22px'} fontSize={isMobile ? "18px" : "18px"} fontWeight="300" mt={isMobile ? '16px' : '16px'} style={{ color: 'rgba(32, 40, 36, 1)' }}>
                Are you sure you want to stop reviewing your registry?
              </Typography>
              <Typography mt={0} fontFamily='Untitled-Sans' lineHeight={'22px'} fontSize={isMobile ? "18px" : "18px"} fontWeight="500" style={{ color: 'rgba(32, 40, 36, 1)' }}>
                You will lose all the recs you have created.
              </Typography>
            </Box>

            <Box
              sx={{
                padding: '0px, 24px, 0px, 24px',
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              <Button variant="contained"
                onClick={() => { handleReset() }}
                sx={{
                  borderRadius: '100px',
                  bgcolor: 'rgba(255, 255, 255, 1)',
                  width: isMobile ? '345px' : '300px',
                  height: isMobile ? '41px' : '41px',
                  color: '#000',
                  '&:hover': { color: '#000', bgcolor: 'rgba(255, 255, 255, 1)', boxShadow: '3px 5px 0px 0px', },
                  boxShadow: '3px 4px 0px 0px rgba(32, 40, 36, 1)',
                  marginTop: '0.3rem',
                  textTransform: 'none',
                  border: '1px solid #000'
                }}>
                <Typography lineHeight={'17.49px !important'} fontFamily='Untitled-Sans' fontSize={isMobile ? "14px" : "14px"} fontWeight="500" style={{ color: 'rgba(32, 40, 36, 1)' }}>
                  Yup, I'm done for now.
                </Typography>
              </Button>

              <Button variant="contained"
                onClick={() => { setShowCancelPopup(false) }}
                sx={{
                  borderRadius: '100px',
                  bgcolor: '#FB9F78',
                  width: isMobile ? '345px' : '300px',
                  height: isMobile ? '41px' : '41px',
                  color: '#000',
                  '&:hover': { color: '#000', bgcolor: '#FB9F78', boxShadow: '3px 5px 0px 0px', },
                  boxShadow: '3px 4px 0px 0px rgba(32, 40, 36, 1)',
                  marginTop: '16px',
                  textTransform: 'none',
                  border: '1px solid #000'
                }}>
                <Typography lineHeight={'17.49px !important'} fontFamily='Untitled-Sans' fontSize={isMobile ? "14px" : "14px"} fontWeight="500" style={{ color: 'rgba(32, 40, 36, 1)' }}>
                  No let's keep reviewing
                </Typography>
              </Button>

            </Box>

            <Box
              sx={{
                height: '20px'
              }}
            />

          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default ReviewRegistry;
