import {
  Box,
  Button,
  LinearProgress,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'; // Import Link from React Router
import CheckLottie from '../assets/Auggie-Check.json';
import ConfettieLottie from '../assets/Auggie-Confetti.json';
import GuideLoading from '../assets/GuideLoading.json';
import pback from "../assets/progress-back.png";
import NavBar from '../components/NavBar';
import { sendReviews } from '../service/ApiServices';

import { selectEmail, selectEmailVerification, selectReviews, selectSource, selectUserRegistered } from '../store/selectors/selectors';

const Loader = ({ setLoading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress >= 80 ? 85 : Math.min(oldProgress + Math.random() * 10, 100);
        if (newProgress === 100) {
        }
        return newProgress;
      });
    }, 1500);

    return () => clearInterval(timer);
  }, []);


  return (
    <>
      <Box display={'flex'} justifyContent={'center'} width={'100%'}>
        <Box style={{ marginTop: isMobile ? '1rem' : '1em', width: isMobile ? '80%' : '50%' }}>
          <LinearProgress variant="determinate" value={progress} sx={{ height: '7px', borderRadius: '10px', backgroundImage: `url(${pback})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: "center center", "& .MuiLinearProgress-bar": { backgroundColor: '#FF8181' } }} />
        </Box>
      </Box>
    </>
  )
}

const ReviewCompleted = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const siteEmail = useSelector(selectEmail);
  const siteSource = useSelector(selectSource);
  const productReviews = useSelector(selectReviews);
  const emailVerification = useSelector(selectEmailVerification);
  const isUserRegistered = useSelector(selectUserRegistered);

  const [loading, setLoading] = useState(true);

  const LottieCheck = {
    loop: false,
    autoplay: true,
    animationData: CheckLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const LottieConfettie = {
    loop: false,
    autoplay: true,
    animationData: ConfettieLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const LoadingGuide = {
    loop: true,
    autoplay: true,
    animationData: GuideLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(sendReviews(siteEmail, siteSource, productReviews))
      .then((response) => {
        // console.log('Reviews Sent Successfully');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Box
        sx={{
          height: loading ? '100vh' : window.innerHeight <= 800 ? '130vh' : window.innerHeight > 800 && window.innerHeight <= 850 && window.innerWidth >= 500 ? '120vh' : '110vh',
          backgroundRepeat: 'repeat',
          backgroundSize: 'cover',
          backgroundColor: '#CBD4BD',
          overflowY: 'auto',
        }}
      >
        <NavBar version={2} />

        {
          loading ? (
            <>
              <Box display="flex" flexDirection="column" alignItems="center" zIndex={0}>
                <Lottie
                  options={LottieConfettie}
                  height={isMobile ? '350px' : '35vh'}
                  width={isMobile ? '100vw' : '27vw'}
                  style={{
                    zIndex: 0,
                    position: 'absolute',
                  }}
                />
              </Box>

              <Box display="flex" flexDirection="column" alignItems="center" sx={{ padding: { xs: '0 20px', sm: '0' }, zIndex: 5 }} mt={isMobile ? 10 : 10}>
                <Box>
                  <Lottie
                    options={LoadingGuide}
                    height={isMobile ? '200px' : '265px'}
                    width={isMobile ? '200px' : '265px'}
                    style={{
                      zIndex: 1,
                      // position: 'absolute',
                    }}
                  />
                </Box>

                <Box sx={{ textAlign: 'center', marginTop: window.innerHeight <= 390 ? '20px' : '50px', marginBottom: '20px', px: { xs: 0, sm: 0 } }}>
                  {
                    isMobile ? (<>
                      <Typography
                        fontFamily="Copernicus"
                        sx={{
                          fontSize: { xs: '26px', sm: '46px' },
                          letterSpacing: -0.8,
                          fontWeight: '800',
                          lineHeight: '39px',
                        }}
                      >
                        Thanks, your part is done!
                      </Typography>
                    </>) : (<>
                      <Typography
                        fontFamily="Copernicus"
                        sx={{
                          fontSize: { xs: '26px', sm: '46px' },
                          fontWeight: '800',
                        }}
                      >
                        Thanks, your part is done!
                      </Typography>
                      {/* <Typography
                        fontFamily="Copernicus"
                        sx={{
                          fontSize: { xs: '26px', sm: '46px' },
                          fontWeight: '800',
                        }}
                      >
                        Reviewing Your Items
                      </Typography> */}
                    </>)
                  }
                </Box>

                <Box>
                  <Typography
                    fontFamily="Untitled-Sans"
                    sx={{
                      textAlign: 'center',
                      fontWeight: '400',
                      fontFamily: 'Untitled-Sans',
                      fontSize: { xs: '16px', sm: '18px' },
                      mb: 2
                    }}
                  >
                    We'll take all the wisdom you shared and turn it into a personalized guide...
                  </Typography>
                </Box>

                <Loader setLoading={setLoading} />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {/* <CheckIcon width={isMobile ? '260px' : '345px'} height={isMobile ? '260px' : '345px'} style={{ marginTop: '-2rem' }} /> */}
                {/* <img src={CheckIcon} alt="" style={{ width: isMobile ? '260px' : '345px', height: isMobile ? '260px' : '345px', marginTop: '-2rem' }} /> */}

                <Lottie
                  options={LottieCheck}
                  height={isMobile ? '260px' : '345px'}
                  width={isMobile ? '260px' : '345px'}
                  style={{
                    marginBottom: '3rem'
                  }}
                />

                <Typography fontFamily={'Untitled-Sans'} fontWeight={500} fontSize={isMobile ? '18px' : '24px'} mt={-5}>
                  GREAT NEWS!
                </Typography>

                <Typography mt={'20px'} color={'rgba(32, 40, 36, 1)'} lineHeight={isMobile ? '40px' : '69px'} textAlign={'center'} fontFamily={'Copernicus'} fontWeight={800} fontSize={isMobile ? '26px' : '46px'}>
                  We'll take it from here!
                </Typography>

                {/* <Typography color={'rgba(32, 40, 36, 1)'} lineHeight={isMobile ? '40px' : '69px'} textAlign={'center'} fontFamily={'Copernicus'} fontWeight={800} fontSize={isMobile ? '26px' : '46px'}>
                  Been Created!
                </Typography> */}

                <Typography mt={'20px'} color={'rgba(32, 40, 36, 1)'} width={isMobile ? '90%' : '466px'} textAlign={'center'} fontFamily={' Untitled-Sans '} lineHeight={'22px'} fontWeight={400} fontSize={isMobile ? '18px' : '18px'}>
                  The info you shared is now being transformed into a sleek little custom registry guide you can share with soon-to-be parents in your circle!
                </Typography>

                <Typography color={'rgba(32, 40, 36, 1)'} mt={'20px'} width={isMobile ? '90%' : '466px'} textAlign={'center'} fontFamily={' Untitled-Sans '} lineHeight={'22px'} fontWeight={400} fontSize={isMobile ? '18px' : '18px'}>
                  Watch your inbox, we'll email your guide as soon as it's ready!
                </Typography>

                {/* <Typography mt={2} width={isMobile ? '65%' : '466px'} textAlign={'center'} fontFamily={' Untitled-Sans '} lineHeight={'22px'} fontWeight={400} fontSize={isMobile ? '18px' : '18px'}>
                  Your wisdom is so valuable
                </Typography>

                <Typography mt={0} width={isMobile ? '65%' : '466px'} textAlign={'center'} fontFamily={' Untitled-Sans '} lineHeight={'22px'} fontWeight={400} fontSize={isMobile ? '18px' : '18px'}>
                  and greatly appreciated!
                </Typography> */}

                {/* <Button variant="contained"
                  sx={{
                    borderRadius: '50px',
                    bgcolor: '#FB9F78',
                    width: isMobile ? '320px' : '452px',
                    color: '#000',
                    '&:hover': { color: '#000', bgcolor: '#FB9F78', boxShadow: '3px 5px 0px 0px', },
                    boxShadow: '3px 5px 0px 0px',
                    marginTop: '2rem',
                    textTransform: 'none',
                    border: '1px solid #000'
                  }}>
                  <Typography fontFamily='Untitled-Sans' fontSize={isMobile ? "14px" : "14px"} fontWeight="500">
                    {emailVerification?.records?.length === 0 ? 'Create account to stay connected' : 'Go to login'}
                  </Typography>
                </Button> */}
                <Button
                  variant="contained"
                  component={Link} // Use Link component
                  to={emailVerification?.records?.length === 0 ? 'https://auggie.com/onboarding' : 'https://auggie.com/login'} // Define the URLs based on conditions
                  sx={{
                    borderRadius: '50px',
                    bgcolor: '#FB9F78',
                    width: isMobile ? '320px' : '452px',
                    color: '#000',
                    '&:hover': { color: '#000', bgcolor: '#FB9F78', boxShadow: '3px 5px 0px 0px' },
                    boxShadow: '3px 5px 0px 0px',
                    marginTop: '2rem',
                    textTransform: 'none',
                    border: '1px solid #000'
                  }}
                >
                  <Typography fontFamily='Untitled-Sans' fontSize={isMobile ? "14px" : "14px"} fontWeight="500">
                    {
                      isUserRegistered ?
                        (
                          <>
                            <a href="https://auggie.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#000' }}>
                              Return to Auggie
                            </a>
                          </>
                        ) : (
                          <>
                            {emailVerification?.records?.length === 0 ? (
                              <a href="https://auggie.com/onboarding" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#000' }}>
                                Create account to stay connected
                              </a>
                            ) : (
                              <a href="https://auggie.com/login" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#000' }}>
                                Go to login
                              </a>
                            )}
                          </>
                        )
                    }
                  </Typography>
                </Button>
              </Box>
            </>
          )
        }
      </Box >
    </>
  );
};

export default ReviewCompleted;
