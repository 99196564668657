import {
    PRODUCTS_SUCCESS,
    RESET_SITE_DETAILS,
    UPDATE_REVIEW,
    UPDATE_SITE_EMAIL,
    UPDATE_SITE_SOURCE,
    UPDATE_USER_DETAILS,
    UPDATE_USER_REGISTERED
} from '../consts/reduxActionTypes';

export const setProducts = (products) => (dispatch) => {
    dispatch({
        type: PRODUCTS_SUCCESS,
        payload: products
    })
}

export const updateReviewList = (review) => (dispatch) => {
    dispatch({
        type: UPDATE_REVIEW,
        payload: review
    })
}

export const updateUserEmail = (email) => (dispatch) => {
    dispatch({
        type: UPDATE_SITE_EMAIL,
        payload: email,
    })
}

export const updateSiteSource = (source) => (dispatch) => {
    dispatch({
        type: UPDATE_SITE_SOURCE,
        payload: source,
    })
}

export const updateUserDetails = (userDetails) => (dispatch) => {
    dispatch({
        type: UPDATE_USER_DETAILS,
        payload: userDetails,
    })
}

export const resetDetails = () => (dispatch) => {
    dispatch({
        type: RESET_SITE_DETAILS,
    })
}

export const setUserRegisteredState = (isUserRegitered) => (dispatch) => {
    dispatch({
        type: UPDATE_USER_REGISTERED,
        payload: isUserRegitered
    })
}