import { combineReducers } from 'redux';

import {
    PRODUCTS_INIT,
    PRODUCTS_SUCCESS,
    RESET_SITE_DETAILS,
    UPDATE_REVIEW,
    UPDATE_SITE_EMAIL,
    UPDATE_SITE_SOURCE,
    UPDATE_USER_DETAILS,
    UPDATE_USER_REGISTERED
} from '../consts/reduxActionTypes';

const siteState = {
    email: null,
    source: null,
    user: null,
    isRegistered: null,
}

const siteReducer = (state = siteState, action) => {
    switch (action.type) {
        case RESET_SITE_DETAILS:
            return {
                product: [],
                reviews: [],
                site: siteState
            }
        case UPDATE_SITE_EMAIL:
            return {
                ...state,
                email: action.payload,
            }
        case UPDATE_SITE_SOURCE:
            return {
                ...state,
                source: action.payload,
            }
        case UPDATE_USER_DETAILS:
            return {
                ...state,
                user: action.payload,
            }
        case UPDATE_USER_REGISTERED:
            return {
                ...state,
                isRegistered: action.payload,
            }
        default:
            return state;
    }
};

const reviewReducer = (state = [], action) => {
    switch (action.type) {
        case UPDATE_REVIEW:
            return [
                ...state,
                action.payload
            ]
        default:
            return state;
    }
};

const productsReducer = (state = [], action) => {
    switch (action.type) {
        case PRODUCTS_INIT:
            return {
                ...state,
            };
        case PRODUCTS_SUCCESS:
            return action.payload
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    product: productsReducer,
    reviews: reviewReducer,
    site: siteReducer
});

export default rootReducer;