import axios from 'axios';
// Prod
// const API_URL='http://3.220.16.97:3001';

// Dev
// const API_URL='http://44.217.136.65:3001';
// const API_URL='http://localhost:3001';
// Prod Links
// const AIRTABLE_BASE_ID = 'appQiJMRXaipAbjax';
// const ProductTable='tblycIONd6q01JUf4';
// const UserTable='tblBAPc2sLCvBRav9';
// const GuideTable='tblnsLZQdmjmrumoJ';
// const RecsTable='tblsLeIiPgf8and4N';

//Dev Links
// const AIRTABLE_BASE_ID = 'appF3tg4RRIyRbX4i';
// const ProductTable='tblnXsi07NQ9iJy9P';
// const UserTable='tblqlzGfms2ESROpU';

const API_URL = process.env.REACT_APP_API_BASE_URL;
const AIRTABLE_BASE_ID = process.env.REACT_APP_AIRTABLE_BASE_ID;
const UserTable = process.env.REACT_APP_USER_TABLE;

const getProducts = (url) => (dispatch) => {
    const payload = {
        url: url,
    }

    return getProductsService(payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        })
}

const getProductsService = async (payload) => {
    const response = await axios.post(`${API_URL}/getProducts`, payload);
    return response;
}

const sendReviews = (email, source, reviews) => (dispatch) => {
    const payload = {
        email: email,
        source: source,
        data: reviews
    }

    return sendReviewsService(payload)
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        })
}

const sendReviewsService = async (payload) => {
    const response = await axios.post(`${API_URL}/addData`, payload);
    return response;
}

const emailVerification = (email) => (dispatch) => {
    return verfifyEmailService(encodeURI(email))
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        })
}

const verfifyEmailService = async (email) => {
    const response = await axios.get(`https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${UserTable}?filterByFormula=Email%3D%22${email}%22`, {
        headers: {
            'Authorization': 'Bearer patSJ5tr6LTOzxkYJ.737df11c51d4ce4d799599ffa7bf0f185f0bf300c582300f9b5282639157b7f1',
            'Cookie': 'brw=brwec061Ix3aEcymj; brwConsent=opt-out; AWSALB=Dp+784V8yHmErfBh03wKs5Xob11Y6ea8qj71bhAw8IvKO01G/GElxS0ESkIqEXlO8MDjP4Drzf+1Bn7KJUEIf0lK6FxRzg/DwVoYAWmIvyzAvua1yRkSsOHfRWbY; AWSALBCORS=Dp+784V8yHmErfBh03wKs5Xob11Y6ea8qj71bhAw8IvKO01G/GElxS0ESkIqEXlO8MDjP4Drzf+1Bn7KJUEIf0lK6FxRzg/DwVoYAWmIvyzAvua1yRkSsOHfRWbY; brw=brwec061Ix3aEcymj; brwConsent=opt-in; AWSALB=0lyZvh5bqhqIXK2D/rreYukE+w6+olUDOPPSLCk/wE8V1mBfP1K4CKl9WsbyFbPFOUL1PHuZFU2czrUSi4pngb/zs2lF5u7c2a+umgMa9+WN7MppSzF1vNm1cABB; AWSALBCORS=0lyZvh5bqhqIXK2D/rreYukE+w6+olUDOPPSLCk/wE8V1mBfP1K4CKl9WsbyFbPFOUL1PHuZFU2czrUSi4pngb/zs2lF5u7c2a+umgMa9+WN7MppSzF1vNm1cABB'
        }
    });
    return response;
}

export {
    emailVerification, getProducts,
    sendReviews
};

