import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk'; // Import redux-thunk
import rootReducer from './reducers/index';

const configureStore = () => {
    const middlewares = [thunk]; // Add thunk to middleware

    if (process.env.NODE_ENV === 'development') {
        middlewares.push(logger);
    }

    const store = createStore(
        rootReducer,
        compose(applyMiddleware(...middlewares), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f)
    );

    return store;
};

export default configureStore;