import { Box, Button, IconButton, Modal, TextField, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation  } from 'react-router-dom';
import arrowblk from '../assets/arrow-blk.png';
import arrow from '../assets/arrow.png';
import { ReactComponent as DislikeIcon } from '../assets/icons/dislike.svg';
import { ReactComponent as LikeIcon } from '../assets/icons/like.svg';
import { ReactComponent as LoveIcon } from '../assets/icons/love.svg';
import line from '../assets/line.png';
import Loader from '../components/Loader';
import NavBar from "../components/NavBar";
import { emailVerification, getProducts } from '../service/ApiServices';
import { setProducts, setUserRegisteredState, updateSiteSource, updateUserDetails, updateUserEmail } from '../store/actions/actions';

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const { token } = useParams();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token');

  const [url, setUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const [userRegistered, setUserRegistered] = useState(token ? true : false);
  const [email, setEmail] = useState('');
  const [urlError, setUrlError] = useState(false);
  const [error, setError] = useState(false);

  const handleStartReviewing = () => {
    setError(false);
    const validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (email !== '' && email.match(validRegex)) {
      const emailLower = email.toLowerCase();
      dispatch(emailVerification(emailLower))
        .then((response) => {
          dispatch(updateUserDetails(response.data));
        })
        .catch((err) => {
          console.log(err);
        })

      setUserRegistered(true);
      dispatch(updateUserEmail(emailLower));
    } else {
      setError(true);
    }
  };

  const onClickHandler = () => {
    setLoader(true);
    if (url.toLowerCase().includes('amazon')) {
      dispatch(updateSiteSource('Amazon'));
    } else if (url.toLowerCase().includes('babylist')) {
      dispatch(updateSiteSource('Babylist'));
    }

    dispatch(getProducts(url))
      .then((response) => {
        dispatch(setProducts(response.data.data));
        // Assuming registration is successful, set userRegistered to true
        navigate('/registry');
        setUserRegistered(true);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // navigate('/invalidURL');
        setUrlError(true);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (token) {
      dispatch(setUserRegisteredState(true));
      const decodedToken = jwtDecode(token);
      dispatch(updateUserEmail(decodedToken?.email.toLowerCase()));
    } else {
      dispatch(setUserRegisteredState(false));
    }
  }, [])

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Box
            sx={{
              height: window.innerHeight <= 800 ? '130vh' : window.innerHeight > 800 && window.innerHeight <= 850 ? '110vh' : '100vh',
              backgroundColor: '#CBD4BD'
            }}
          >
            <NavBar version={1} />

            <Box display="flex" flexDirection="column" alignItems="center" style={{ padding: '0px 10px', margin: isMobile ? '40px 0px' : '60px 0px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <DislikeIcon width={isMobile ? "70px" : "120px"} height={isMobile ? "70px" : "120px"} style={{ marginRight: '12px' }} />
                <LoveIcon width={isMobile ? "70px" : "120px"} height={isMobile ? "70px" : "120px"} style={{ marginRight: '12px' }} />
                <LikeIcon width={isMobile ? "70px" : "120px"} height={isMobile ? "70px" : "120px"} />
              </Box>

              <Box style={{ margin: isMobile ? '30px 0px' : '30px 0px 20px 0px' }}>
                {isMobile ? (<>
                  <Typography textAlign={'center'} fontFamily='Copernicus' fontSize={isMobile ? "26px" : "46px"} fontWeight="800" style={{ width: '372px' }}>
                    Start your guide by
                  </Typography>
                  <Typography textAlign={'center'} fontFamily='Copernicus' fontSize={isMobile ? "26px" : "46px"} fontWeight="800" style={{ width: '372px' }}>
                    reviewing your registry
                  </Typography>
                </>) : (<>
                  <Typography textAlign={'center'} fontFamily='Copernicus' fontSize={isMobile ? "26px" : "46px"} fontWeight="800" style={{ maxWidth: '1100px' }}>
                    Start your guide by reviewing your registry
                  </Typography>
                </>)}

              </Box>

              <Box>
                <Typography width={isMobile ? '353px' : '456px'} fontFamily='Untitled-Sans' textAlign="center" fontSize={isMobile ? "16px" : "18px"} lineHeight={isMobile ? '30px' : '21px'} fontWeight="400">
                  Help your expecting friends cut through the noise by providing unfiltered advice on registry loves, likes and “ehs” to make their registry experience intentional and fun.
                </Typography>
              </Box>

              <Box style={{ marginTop: '35px', width: isMobile ? '370px' : '712px' }}>
                {
                  isMobile ?
                    (<>
                      <Typography width={isMobile ? '370px' : '370px'} textAlign={'center'} fontFamily="Copernicus" fontSize={isMobile ? "14px" : "21px"} fontWeight="700">
                        Paste your registry URL below
                      </Typography>
                      <Typography width={isMobile ? '370px' : '370px'} textAlign={'center'} fontFamily="Copernicus" fontSize={isMobile ? "14px" : "21px"} fontWeight="700">
                        to convert it into a guide!
                      </Typography>
                    </>)
                    :
                    (<>
                      <Typography width={isMobile ? '370px' : '712px'} textAlign={'center'} fontFamily="Copernicus" fontSize={isMobile ? "14px" : "21px"} fontWeight="700">
                        Paste your registry URL below to convert it into a guide!
                      </Typography>
                    </>)
                }
              </Box>

              <Box display='flex' alignItems='center' style={{ marginTop: '20px' }}>
                <TextField
                  placeholder='https://'
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  error={urlError}
                  variant="outlined"
                  style={{
                    width: isMobile ? '295px' : '400px',
                    height: isMobile ? '60px' : '60px',
                    marginRight: '10px'
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: 10,
                      backgroundColor: "#fff",
                      border: urlError ? '1px solid rgba(218, 49, 79, 1)' : '2px solid rgba(32, 40, 36, 1)',
                      color: urlError ? 'rgba(218, 49, 79, 1)' : '#000',
                    },
                    style: {
                      fontFamily: 'Untitled-Sans',
                      fontWeight: '400',
                      fontSize: '17.5px',
                      lineHeight: '24px',
                    }
                  }}
                />

                <IconButton onClick={onClickHandler}
                  sx={{
                    borderRadius: '50%',
                    bgcolor: url === '' ? '#fff' : '#FB9F78',
                    width: '58px',
                    height: '58px',
                    color: url === '' ? '#2028244D' : '#000',
                    '&:hover': { color: url === '' ? '#2028244D' : '#000', bgcolor: url === '' ? '#fff' : '#FB9F78', boxShadow: url === '' ? '2px 3px 0px 0px #2028244D' : '2px 3px 0px 0px', },
                    boxShadow: url === '' ? '2px 3px 0px 0px #2028244D' : '2px 3px 0px 0px',
                    marginTop: '-0.25rem',
                    textTransform: 'none',
                    border: url === '' ? '2px solid rgba(32, 40, 36, 0.3)' : '2px solid rgba(32, 40, 36, 1)'
                  }}
                >
                  {/* <ArrowForwardIcon style={{ width: isMobile ? '26.5px' : '26.5px', height: isMobile ? '19px' : '19px' }} /> */}
                  <img src={url === '' ? arrow : arrowblk} alt={'=>'} style={{ width: isMobile ? '26.5px' : '26.5px', height: isMobile ? '19px' : '19px' }} />
                </IconButton>
              </Box>

              {
                urlError && (
                  <Box style={{ marginTop: isMobile ? '20px' : '35px' }}
                    sx={{
                      display: 'flex',
                      alignItems: 'left',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography display="inline-block" fontFamily="Untitled-Sans" fontSize={isMobile ? "12px" : "14px"} fontWeight="700" mb={2} textAlign={isMobile ? "left" : "center"} lineHeight={'12px'} color="rgba(218, 49, 79, 1)">
                      We’re sorry, the URL you provided did not work.
                    </Typography>

                    <Typography fontFamily="Untitled-Sans" fontSize={isMobile ? "12px" : "14px"} fontWeight="700" mb={0.5} textAlign={isMobile ? "left" : "center"} lineHeight={'15px'} color="rgba(218, 49, 79, 1)">
                      Make sure the link is a valid URL for a Amazon or Babylist registry (and not a single product on your registry.)
                    </Typography>
                  </Box>
                )
              }

              <Box style={{ marginTop: isMobile ? '20px' : '35px' }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography p={0.65} display="inline-block" fontFamily="Pitch" fontSize="14px" fontWeight="600" mb={0.25} textAlign="center" letterSpacing={0.3} sx={{ bgcolor: '#E1FFB2', }}>
                  Only Amazon and Babylist are
                </Typography>

                <Typography p={0.5} fontFamily="Pitch" fontSize="14px" fontWeight="600" mb={0.25} textAlign="center" letterSpacing={0.3} sx={{ bgcolor: '#E1FFB2', }}>
                  currently supported. More to come!
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Popup for user registration */}
          <Modal
            open={!userRegistered}
            onClose={() => { if (email !== '') { setUserRegistered(true) } }}
            aria-labelledby="registration-modal-title"
            aria-describedby="registration-modal-description"
            style={{
              display: 'flex',
              alignItems: isMobile ? 'flex-end' : 'center',
              justifyContent: 'center',
            }}
            disableEscapeKeyDown
            disableAutoFocus={true}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundColor: '#FCF8F6',
                  padding: isMobile ? '12px, 0px, 48px, 0px' : '24px',
                  textAlign: 'center',
                  width: isMobile ? '100vw' : '441px',
                  height: isMobile ? '473px' : '393px',
                  borderRadius: isMobile ? "35px 35px 0px 0px" : "20px",
                  outline: 'none',
                }}
              >
                {
                  isMobile && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <img src={line} alt="" style={{ width: '60px', marginTop: '-20px' }} />
                    </Box>
                  )
                }
                <Box
                  sx={{
                    maxWidth: '393px',
                    maxHeight: '155px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography letterSpacing={"-1px"} fontFamily='Copernicus' fontSize={isMobile ? "26px" : "26px"} fontWeight="800" style={{ maxWidth: isMobile ? '306px' : '393px', color: 'rgba(32, 40, 36, 1)' }} lineHeight={isMobile ? '39px' : '39px'}>
                    Let's get started reviewing your registry!
                  </Typography>
                  <Typography fontFamily='Untitled-Sans' fontSize={isMobile ? "18px" : "18px"} fontWeight="400" mt={isMobile ? '16px' : '16px'} style={{ color: 'rgba(32, 40, 36, 1)' }}>
                    Enter your email address to get started
                  </Typography>
                </Box>

                <Box
                  sx={{
                    padding: '18px',
                    maxWidth: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '10px'
                  }}
                >
                  <TextField
                    placeholder='Email address'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    sx={{ backgroundColor: 'white', borderRadius: '8px !important', maxHeight: '58px', width: isMobile ? '346px' : '393px', border: 'border: 1px solid rgba(218, 218, 218, 1)' }}
                    inputProps={{
                      style: {
                        fontFamily: 'Untitled-Sans',
                        fontWeight: '400',
                        fontSize: '18px',
                        lineHeight: '22.48px',
                      }
                    }}
                    error={error}
                    helperText={error && 'Email is not in correct format.'}
                  />
                </Box>

                <Box
                  sx={{
                    padding: '0px, 24px, 0px, 24px',
                    maxWidth: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '10px'
                  }}
                >
                  <Button onClick={handleStartReviewing} variant="contained"
                    sx={{
                      borderRadius: '100px',
                      bgcolor: '#FB9F78',
                      width: isMobile ? '345px' : '300px',
                      height: isMobile ? '41px' : '41px',
                      color: '#000',
                      '&:hover': { color: '#000', bgcolor: '#FB9F78', boxShadow: '3px 5px 0px 0px', },
                      boxShadow: '3px 4px 0px 0px rgba(32, 40, 36, 1)',
                      marginTop: '0.3rem',
                      textTransform: 'none',
                      border: '1px solid #000'
                    }}>
                    <Typography lineHeight={'17.49px !important'} fontFamily='Untitled-Sans' fontSize={isMobile ? "14px" : "14px"} fontWeight="500" style={{ color: 'rgba(32, 40, 36, 1)' }}>
                      Start reviewing my registry
                    </Typography>
                  </Button>

                </Box>

                <Typography fontFamily='Untitled-Sans' fontSize={isMobile ? "14px" : "14px"} fontWeight="500" sx={{ mt: 2, color: 'rgba(32, 40, 36, 1)' }}><a href="https://www.auggie.com" target="_self" style={{ color: 'rgba(32, 40, 36, 1)' }}>Not ready to review yet? Go back to Auggie Home</a></Typography>

                <Box
                  sx={{
                    padding: '0px, 24px, 0px, 24px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '12px',
                    maxWidth: '393px'
                  }}
                >
                  <Typography width={isMobile ? '350px' : '350px'} textAlign={'left'} fontFamily='Untitled-Sans' fontSize={isMobile ? "14px" : "14px"} fontWeight="400" style={{ color: 'rgba(32, 40, 36, 1)' }}>By clicking 'Start reviewing my registry' you agree to the Auggie <a style={{ color: "#000" }} href="https://auggie.com/terms-of-service" target="_blank">Terms</a> & <a style={{ color: '#000' }} href="https://auggie.com/privacy-policy" target="_blank">Privacy Policy</a></Typography>
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      )
      }
    </>
  );
};

export default HomePage;
